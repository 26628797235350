import { Button, ButtonGroup, styled } from '@mui/material';

const StyledButton = styled(Button)(({ theme, selected }) => ({
  backgroundColor: selected ? theme.palette.primary.dark : 'default',
  color: selected ? theme.palette.primary.contrastText : 'default',
}));

const TimeSelector = ({ selectedButton, handleButtonClick }) => {
  return (
    <ButtonGroup
      variant="contained"
      size="small"
      aria-label="Days range button group"
    >
      <StyledButton
        sx={{ width: 120 }}
        selected={selectedButton === 'b1'}
        onClick={() => handleButtonClick('b1', 1)}
      >
        24 hours
      </StyledButton>
      <StyledButton
        sx={{ width: 120 }}
        selected={selectedButton === 'b2'}
        onClick={() => handleButtonClick('b2', 7)}
      >
        1 Week
      </StyledButton>
      <StyledButton
        sx={{ width: 120 }}
        selected={selectedButton === 'b3'}
        onClick={() => handleButtonClick('b3', 30)}
      >
        1 Month
      </StyledButton>
      <StyledButton
        sx={{ width: 120 }}
        selected={selectedButton === 'b4'}
        onClick={() => handleButtonClick('b4', 365)}
      >
        1 Year
      </StyledButton>
      <StyledButton
        sx={{ width: 120 }}
        selected={selectedButton === 'b5'}
        onClick={() => handleButtonClick('b5', -1)}
      >
        All
      </StyledButton>
    </ButtonGroup>
  );
};

export default TimeSelector;
