import React from 'react';
import {
  Alert,
  Avatar,
  Box,
  Grid,
  InputAdornment,
  Modal,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import axios from 'axios';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getOperatingSystem, getBrowser } from '../../../utils/platform';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import EmailIcon from '@mui/icons-material/Email';
import LoadingButton from '../../../shared/components/ui/LoadingButton';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const resetSchema = Yup.object().shape({
  email: Yup.string().email('Invalid format').required('Required'),
  os: Yup.string(),
  window: Yup.string(),
});

const ResetModal = ({ isOpen, setIsOpen }) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = React.useState(false);
  const [resMessage, setResMessage] = React.useState();
  const [resErrorMessage, setResErrorMessage] = React.useState();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: { email: '' },
    resolver: yupResolver(resetSchema),
  });

  const handleReset = React.useCallback(
    async (formData) => {
      // console.log(formData);
      setResMessage(null);
      setResErrorMessage(null);
      setIsLoading(true);

      try {
        const recaptchaToken = await executeRecaptcha('reset_password');

        // eslint-disable-next-line no-unused-vars
        const { data } = await axios.post(
          `${process.env.REACT_APP_API_URL}/users/reset-pwd`,
          { ...formData, recaptchaToken },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        // console.log(data);
        setResMessage(data.message);
        reset();
      } catch (error) {
        setResErrorMessage(
          error.response?.data.message ||
            'Unable to reset password. Please try again.'
        );
      }

      setIsLoading(false);
    },
    [reset, executeRecaptcha]
  );

  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen(!isOpen)}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box
        maxWidth='sx'
        justifyContent='center'
        alignItems='center'
        sx={{
          position: 'absolute',
          top: '40%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          border: `1px solid ${theme.palette.primary.dark}`,
          borderRadius: '8px',
          boxShadow: 12,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOpenIcon />
        </Avatar>
        <Grid
          container
          direction='column'
          alignItems='center'
          justifyContent='center'
          spacing={2}
        >
          <Grid item xs={8}>
            <Typography variant='h6'>Password Reset</Typography>
          </Grid>
          <Grid item xs={8}>
            <Box
              component='form'
              onSubmit={handleSubmit(handleReset)}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                {...register('email')}
                margin='normal'
                required
                fullWidth
                id='email'
                label='Email'
                name='email'
                autoComplete='email'
                autoFocus
                {...(errors.email && {
                  error: true,
                  helperText: errors.email.message,
                })}
                variant='filled'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                {...register('os')}
                sx={{ display: 'none' }}
                id='os'
                name='os'
                value={getOperatingSystem(window)}
              />
              <TextField
                hidden
                {...register('browser')}
                sx={{ display: 'none' }}
                id='browser'
                name='browser'
                value={getBrowser(window)}
              />

              {/* <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{ mt: 3, mb: 2 }}
              >
                Reset Password
              </Button> */}

              <LoadingButton
                type='submit'
                variant='contained'
                fullWidth
                sx={{ mt: 2, mb: 2 }}
                isLoading={isLoading}
              >
                Reset Password
              </LoadingButton>
            </Box>
          </Grid>

          <Grid item xs={8}>
            {resMessage && (
              <Alert severity='success' sx={{ width: '100%' }}>
                {resMessage}
              </Alert>
            )}

            {resErrorMessage && (
              <Alert severity='error' sx={{ width: '100%' }}>
                {resErrorMessage}
              </Alert>
            )}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ResetModal;
