import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Alert, Box, Grid, Typography } from '@mui/material';
import SimpleCard from '../../../shared/components/ui/SimpleCard';
import Tile from './Tile';
import { capitalizeFirstLetter } from '../../../utils/helpers';
import TimeSelector from './TimeSelector';

const Files = ({ authToken }) => {
  const [daysPast, setDaysPast] = useState(1);
  const [selectedButton, setSelectedButton] = useState('b1');
  const [fileStats, setFileStats] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchFileStats = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/file-stats?days=${daysPast}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        // sort
        data.stats.sort((a, b) => {
          if (a.status < b.status) return -1;
          if (a.status > b.status) return 1;
          return 0;
        });

        setFileStats(data.stats);
      } catch (error) {
        setError(error.response?.data.message || 'Error processing request.');
      }
    };

    fetchFileStats();
  }, [authToken, daysPast]);

  const handleButtonClick = (id, range) => {
    setSelectedButton(id);
    setDaysPast(range);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <>
          <Typography variant="body1" fontWeight={700} sx={{ mb: 2 }}>
            File Stats
          </Typography>

          <TimeSelector
            selectedButton={selectedButton}
            handleButtonClick={handleButtonClick}
          />
        </>
      </Box>

      {error ? (
        <Alert severity="warning">{error}</Alert>
      ) : fileStats.length > 0 ? (
        <Grid container spacing={2}>
          {fileStats?.map((item, idx) => {
            return (
              <Grid key={idx} item xs={12} md={3}>
                <SimpleCard height={90} elevation={4}>
                  <Tile caption={capitalizeFirstLetter(item.status)}>
                    {item.count}
                  </Tile>
                </SimpleCard>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Typography textAlign="center">
          No records for the selected range
        </Typography>
      )}
    </>
  );
};

export default Files;
